<template>
  <div>
    <el-card class="editContainer">
      <el-form class="vendorFrom" label-position="right" label-width="100px">
        <div class="secondTitle title">营业执照</div>
        <!-- <span class="assistText">提供至平台介绍处查看</span> -->

        <el-form-item label="商户运营资质">
          <el-button @click="addQualify">添加</el-button>
        </el-form-item>
        <el-form-item>
          <div v-for="(item, index) in businessLicence" :key="index">
            <span class="businessLicenceName"> <span> 名称:</span>{{ item.businessLicence }} </span>
            <span class="businessLicenceIndex"> <span>编号: </span>{{ item.licenceNumber }} </span>
            <el-button type="text" @click="editQualify(item, index)">编辑</el-button>
            <el-button type="text" @click="showDelete(index)" class="deleteBtnText">删除</el-button>
          </div>
        </el-form-item>

        <el-form-item label="添加执照" prop="">
          <bm-upload type="businessLicense" v-model="qualifyImage" :limit="9"></bm-upload>
        </el-form-item>

        <div class="secondTitle title">价格说明</div>
        <el-form-item label="编辑价格说明">
          <div class="priceDesc">
            <bm-tinymce v-model="priceIntro" :height="300"></bm-tinymce>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveMallContent">保存</el-button>
        </el-form-item>
      </el-form>

      <!-- 新增 && 编辑平台资质-->
      <el-dialog :close-on-click-modal="false" :title="qualifyDialogTitle" :visible.sync="qualifyDialog" width="25%">
        <el-form ref="businessLicenceFrom" :model="businessLicenceFrom" label-width="80px">
          <el-form-item label="资质名称">
            <el-input v-model="businessLicenceFrom.businessLicence"></el-input>
          </el-form-item>
          <el-form-item label="资质编号">
            <el-input v-model="businessLicenceFrom.licenceNumber"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button round @click="qualifyDialog = false">取消</el-button>
          <el-button round type="primary" @click="confirmQualify">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 运营资质的弹窗
      businessLicenceFrom: {
        licenceNumber: '',
        businessLicence: ''
      },
      // 运营资质
      businessLicence: [],
      // 运营资质的弹窗显示
      qualifyDialog: false,
      // 运营弹窗弹窗顶部标题
      qualifyDialogTitle: '',

      // 商城内容设置的营业照片
      qualifyImage: [],
      // 商城内容设置的价格说明
      priceIntro: '',
      // 判断是否是编辑
      editIndex: -1,
      tempId: ''
    }
  },
  created() {
    // 获取商城内容设置

    this.getMallContent()
  },
  methods: {
    // ==================运营资质====================
    // 点击 新增 && 修改商户运营资质
    editQualify(value, index) {
      this.qualifyDialogTitle = '修改商户运营资质'
      this.qualifyDialog = true
      this.businessLicenceFrom = value
      this.editIndex = index
    },
    addQualify() {
      this.qualifyDialogTitle = '添加商户运营资质'
      this.qualifyDialog = true
    },

    // 新增或者修改运营资质
    confirmQualify() {
      if (this.editIndex !== -1) {
        // 修改
        this.editIndex = -1
      } else {
        this.businessLicence.push(this.businessLicenceFrom)
      }
      this.businessLicenceFrom = {}
      this.qualifyDialog = false
    },
    //删除运营资质
    showDelete(index) {
      this.businessLicence.splice(index, 1)
    },
    // 获取内容设置
    getMallContent() {
      this.$api.base
        .base('getSysMallContent')
        .then(res => {
          let { priceExplain, businessLicencePhotoList, businessLicence, id } = res
          this.priceIntro = priceExplain
          this.qualifyImage = businessLicencePhotoList
          this.businessLicence = businessLicence || []
          this.tempId = id
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 保存内容设置
    saveMallContent() {
      let params = {
        businessLicencePhotoList: this.qualifyImage,
        priceExplain: this.priceIntro,
        id: this.tempId,
        businessLicence: this.businessLicence
      }
      if (this.tempId) {
        const loading = this.$loading({
          lock: true,
          text: '正在提交，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.base
          .base('putSysMallContent', params)
          .then(res => {
            this.$message.success('修改成功')
            this.getMallContent()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      } else {
        const loading = this.$loading({
          lock: true,
          text: '正在提交，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.base
          .base('postSysMallContent', params)
          .then(res => {
            this.$message.success('添加成功')
            this.getMallContent()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  height: calc(100% - 40px);
  .vendorFrom {
    margin-left: 20px;

    .title {
      font-size: 20px;
      line-height: 60px;
    }

    .priceDesc {
      width: 800px;
    }
    .assist {
      .assistText;
    }
    .businessLicenceName {
      margin-right: 30px;
    }

    .businessLicenceName,
    .businessLicenceIndex {
      display: inline-block;
      width: 350px;
      span {
        margin-right: 10px;
      }
    }
  }

  .el-dialog {
    .el-input {
      width: 250px;
    }
  }
}
</style>
